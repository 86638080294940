import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { utils, writeFile } from "xlsx";
import { db } from "../firebase/firebase-config";
import { doc, getDoc, collection, getDocs } from "firebase/firestore";
import { FiDownload, FiSearch } from "react-icons/fi";

const SalesPriceFile = () => {
  const { fileId } = useParams();
  const navigate = useNavigate();
  const [categorizedItems, setCategorizedItems] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [userSalesCodes, setUserSalesCodes] = useState([]);
  const [userCustomerCodes, setUserCustomerCodes] = useState([]);

  useEffect(() => {
    const auth = getAuth();
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        const userDocRef = doc(db, "users", user.uid);
        const userDoc = await getDoc(userDocRef);
        if (userDoc.exists()) {
          const userData = userDoc.data();
          setUserSalesCodes(Array.isArray(userData.salesPersonCodes) ? userData.salesPersonCodes : []);
          setUserCustomerCodes(Array.isArray(userData.customerCodes) ? userData.customerCodes : []);
        }
      } else {
        navigate("/AccessDenied");
      }
    });
  }, [navigate]);

  useEffect(() => {
    const fetchProductInfoAndFile = async () => {
      try {
        setIsLoading(true);
        setError(null);

        const priceFileAccessRef = doc(db, "pricefileAccess", fileId);
        const priceFileAccessSnap = await getDoc(priceFileAccessRef);

        if (priceFileAccessSnap.exists()) {
          const priceFileAccessData = priceFileAccessSnap.data();
          const allowedSalesCodes = priceFileAccessData.salescodes || [];
          const allowedCustomers = priceFileAccessData.customers || [];

          if (
            userSalesCodes.includes("admin") ||
            userSalesCodes.some((code) => allowedSalesCodes.includes(code)) ||
            userCustomerCodes.some((code) => allowedCustomers.includes(code))
          ) {
            const productInfoSnapshot = await getDocs(collection(db, "productinfo"));
            const productInfo = {};
            productInfoSnapshot.forEach((doc) => {
              const data = doc.data();
              productInfo[doc.id] = { userFriendlyDesc: data.userFriendlyDesc, category: data.category || "Other" };
            });

            const priceFileRef = doc(db, "pricefiles", fileId);
            const priceFileSnap = await getDoc(priceFileRef);

            if (priceFileSnap.exists()) {
              organizeItemsByCategory(priceFileSnap.data().items || [], productInfo);
            } else {
              throw new Error("No such document in pricefiles collection!");
            }
          } else {
            setError("Access Denied: You do not have permission to view this price file.");
            navigate("/AccessDenied");
          }
        } else {
          throw new Error("No such document in pricefileAccess collection!");
        }
      } catch (error) {
        setError(`Failed to fetch data: ${error.message}`);
      }
      setIsLoading(false);
    };

    if (userSalesCodes.length > 0 || userCustomerCodes.length > 0) {
      fetchProductInfoAndFile();
    }
  }, [fileId, userSalesCodes, userCustomerCodes, navigate]);

  const organizeItemsByCategory = (items, productInfo) => {
    const currentDate = new Date();
  
    // Convert "DD/MM/YYYY" → "YYYY-MM-DD" to ensure correct comparison
    const convertToSortableDate = (dateString) => {
      if (!dateString) return null;
      const [day, month, year] = dateString.split("/");
      return `${year}-${month}-${day}`; // YYYY-MM-DD format
    };
  
    const currentDateString = convertToSortableDate(
      currentDate.toLocaleDateString("en-GB").split("/").map(num => num.padStart(2, "0")).join("/")
    );
  
    console.log("Current Date (Sortable):", currentDateString);
  
    const categories = items.reduce((acc, item) => {
      let { itemcode, desc, unitprice, startdate, enddate } = item;
      itemcode = decodeURIComponent(itemcode);
  
      // Convert stored start/end dates to "YYYY-MM-DD"
      const startDateFormatted = convertToSortableDate(startdate);
      const endDateFormatted = convertToSortableDate(enddate);
  
      console.log(`Item: ${itemcode}, Start: ${startDateFormatted}, End: ${endDateFormatted}`);
  
      // Compare using correctly formatted dates
      if (startDateFormatted <= currentDateString && endDateFormatted >= currentDateString) {
        let productData = productInfo[itemcode] || productInfo[itemcode.replace(/\//g, '-')];
        const category = productData?.category || 'Other';
        const description = productData?.userFriendlyDesc || desc;
  
        if (!acc[category]) acc[category] = [];
        acc[category].push({ itemcode, desc: description, unitprice: Number(unitprice).toFixed(2) });
      }
  
      return acc;
    }, {});
  
    setCategorizedItems(categories);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value.toLowerCase());
  };

  const exportTablesToExcel = () => {
    const wb = utils.book_new();
    const ws = utils.aoa_to_sheet([["Price File"]]);
    let currentRow = 3;
    const { Other, ...restCategories } = categorizedItems;
    const sortedCategories = Object.keys(restCategories).sort();

    Object.entries({ ...restCategories, Other }).forEach(([category, items]) => {
      utils.sheet_add_aoa(ws, [[category]], { origin: { r: currentRow++, c: 0 } });
      const itemsData = items.map((item) => [item.itemcode, item.desc, `£${item.unitprice}`]);
      utils.sheet_add_aoa(ws, itemsData, { origin: { r: currentRow, c: 0 } });
      currentRow += itemsData.length + 2;
    });

    ws["!cols"] = [{ wch: 20 }, { wch: 100 }, { wch: 10 }];
    utils.book_append_sheet(wb, ws, "Price File");

    const filename = `${fileId}.xlsx`;
    writeFile(wb, filename);
  };

  return (
    <div className="min-h-screen bg-gray-900 text-white p-6">
      {/* Header */}
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-3xl font-bold">Price File: {fileId}</h1>
        <button
          onClick={exportTablesToExcel}
          className="flex items-center gap-2 bg-primary hover:bg-opacity-80 text-white px-4 py-2 rounded-md transition-all"
        >
          <FiDownload /> Export to Excel
        </button>
      </div>

      {/* Search Bar */}
      <div className="relative w-full max-w-lg mx-auto mb-6">
        <input
          type="text"
          placeholder="Search by Code or Description..."
          value={searchQuery}
          onChange={handleSearchChange}
          className="w-full px-4 py-2 bg-gray-800 border border-gray-700 rounded-md focus:ring focus:ring-primary text-white placeholder-gray-400 shadow-md"
        />
        <FiSearch className="absolute right-3 top-3 text-gray-500 text-xl" />
      </div>

      {/* Product Table - One Category at a Time */}
      <div className="space-y-8">
        {Object.entries(categorizedItems)
          .sort(([a], [b]) => (a === "Other" ? 1 : b === "Other" ? -1 : 0)) // Ensure "Other" is always last
          .map(([category, items]) => {
            const filteredItems = items.filter(
              (item) =>
                item.itemcode.toLowerCase().includes(searchQuery) ||
                item.desc.toLowerCase().includes(searchQuery)
            );

            if (filteredItems.length === 0) return null;

            return (
              <div key={category} className="bg-gray-800 p-6 rounded-lg shadow-lg">
                <h3 className="text-xl font-semibold mb-3 text-primary">{category}</h3>
                <ul className="space-y-2">
                  {filteredItems.map((item, index) => (
                    <li
                      key={index}
                      className="flex justify-between border-b border-gray-700 pb-2 px-4 py-2 transition-all hover:bg-gray-700 hover:shadow-lg rounded-md"
                    >
                      <span>{item.itemcode}</span>
                      <span className="flex-1 text-center">{item.desc}</span>
                      <span className="text-primary font-bold">£{item.unitprice}</span>
                    </li>
                  ))}
                </ul>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default SalesPriceFile;
