import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { collection, getDocs, query, where, doc, getDoc } from "firebase/firestore";
import { db } from "../firebase/firebase-config";
import { FiSearch, FiUser } from "react-icons/fi";

const CustomerList = () => {
  const [customers, setCustomers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [userSalesCodes, setUserSalesCodes] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      if (user) {
        const fetchUserData = async () => {
          const userDocRef = doc(db, "users", user.uid);
          const userDoc = await getDoc(userDocRef);
          if (userDoc.exists()) {
            setUserSalesCodes(Array.isArray(userDoc.data().salesPersonCodes) ? userDoc.data().salesPersonCodes : []);
          } else {
            console.error("No user data found!");
          }
        };
        fetchUserData();
      } else {
        console.error("User not logged in");
      }
    });
  }, []);

  useEffect(() => {
    const fetchCustomers = async () => {
      if (userSalesCodes.length === 0) return;
      setIsLoading(true);

      let snapshot;
      if (userSalesCodes.includes("admin")) {
        snapshot = await getDocs(collection(db, "customerinfo"));
      } else {
        const q = query(collection(db, "customerinfo"), where("salescode", "array-contains-any", userSalesCodes));
        snapshot = await getDocs(q);
      }

      setCustomers(snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
      setIsLoading(false);
    };
    fetchCustomers();
  }, [userSalesCodes]);

  const handlePriceFileAccess = async (customerId) => {
    const customerDocRef = doc(db, "customerinfo", customerId);
    const customerDoc = await getDoc(customerDocRef);

    if (customerDoc.exists()) {
      const customerData = customerDoc.data();
      const allowedSalesCodes = customerData.salescode || [];
      const pricefile = customerData.pricefile;

      if (userSalesCodes.includes("admin") || userSalesCodes.some((code) => allowedSalesCodes.includes(code))) {
        navigate(`/SalesPriceFile/${pricefile}`);
      } else {
        navigate("/AccessDenied");
      }
    } else {
      console.error(`Customer with ID ${customerId} does not exist`);
      navigate("/AccessDenied");
    }
  };

  const filteredCustomers = customers.filter((customer) =>
    customer.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  if (isLoading) return <p className="text-center text-white">Loading customers...</p>;

  return (
    <div className="min-h-screen bg-gray-900 text-white p-6">
      {/* Search Bar */}
      <div className="flex justify-center mb-6">
        <div className="relative w-full max-w-lg">
          <input
            type="text"
            placeholder="Search Customers..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="w-full px-4 py-2 bg-gray-800 border border-gray-700 rounded-md focus:ring focus:ring-primary text-white placeholder-gray-400 shadow-md"
          />
          <FiSearch className="absolute right-3 top-3 text-gray-500 text-xl" />
        </div>
      </div>

      {/* Customer Grid */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
        {filteredCustomers.map((customer) => (
          <div
            key={customer.id}
            onClick={() => handlePriceFileAccess(customer.id)}
            className="relative p-6 bg-gray-800 rounded-lg shadow-lg hover:shadow-xl transition-all transform hover:-translate-y-1 cursor-pointer"
          >
            <FiUser className="absolute top-3 right-3 text-primary text-2xl" />
            <h3 className="text-xl font-semibold">{customer.name}</h3>
            <p className="text-gray-400 text-sm mt-1">{customer.id}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CustomerList;
