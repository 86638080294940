import React, { useState, useEffect } from "react";
import Select from "react-select";
import { db } from "../firebase/firebase-config";
import { collection, getDocs, doc, getDoc, setDoc, updateDoc, arrayUnion } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import logAction from "./logger";
import { FiUserPlus, FiCheckCircle, FiAlertTriangle } from "react-icons/fi";

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    backgroundColor: "#374151",
    borderColor: state.isFocused ? "#b2894d" : "#4b5563",
    color: "white",
    padding: "4px",
    borderRadius: "6px",
    boxShadow: state.isFocused ? "0 0 5px #b2894d" : "none",
    "&:hover": { borderColor: "#b2894d" },
  }),
  menu: (provided) => ({
    ...provided,
    backgroundColor: "#1f2937",
    borderRadius: "6px",
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused ? "#b2894d" : "#1f2937",
    color: state.isFocused ? "black" : "white",
    padding: "10px",
    cursor: "pointer",
  }),
  multiValue: (provided) => ({
    ...provided,
    backgroundColor: "#b2894d",
    color: "black",
  }),
  multiValueLabel: (provided) => ({
    ...provided,
    color: "black",
  }),
  singleValue: (provided) => ({
    ...provided,
    color: "white",
  }),
  input: (provided) => ({
    ...provided,
    color: "white", // Ensures the typed text is white
  }),
  placeholder: (provided) => ({
    ...provided,
    color: "white", // Ensures placeholder text is white
  }),
};

const CreateCustomerForm = () => {
  const [priceFiles, setPriceFiles] = useState([]);
  const [selectedPriceFile, setSelectedPriceFile] = useState(null);
  const [customerNumber, setCustomerNumber] = useState("");
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [salesCode, setSalesCode] = useState("");
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const auth = getAuth();
  const currentUser = auth.currentUser;

  const salesCodeOptions = [
    { value: "1", label: "1 - Adam Armstrong" },
    { value: "2", label: "2 - Oliver Harrison" },
    { value: "3", label: "3 - Joe Forte" },
    { value: "4", label: "4 - R & D Wightwick" },
    { value: "5", label: "5 - TBC" },
    { value: "6", label: "6 - Stuart Small" },
    { value: "7", label: "7 - John Dunn" },
    { value: "8", label: "8 - Teresa Richards" },
    { value: "9", label: "9 - John Dunn" },
    { value: "CLOSED", label: "Closed" },
    { value: "TL", label: "TL" },
    { value: "SERVICE", label: "Service" },
  ];

  useEffect(() => {
    const fetchPriceFiles = async () => {
      const snapshot = await getDocs(collection(db, "pricefiles"));
      const priceFilesData = snapshot.docs.map((doc) => ({
        value: doc.id,
        label: doc.id,
      }));
      setPriceFiles(priceFilesData);
    };

    fetchPriceFiles();
  }, []);

  const handleCreateCustomer = async (e) => {
    e.preventDefault();
    setError(null);
    setSuccessMessage(null);

    if (!customerNumber || !email || !name || !selectedPriceFile || !salesCode) {
      setError("All fields are required.");
      return;
    }

    const customerDocRef = doc(db, "customerinfo", customerNumber);
    const customerDocSnap = await getDoc(customerDocRef);

    if (customerDocSnap.exists()) {
      setError("Error: Customer number already exists.");
      return;
    }

    try {
      await setDoc(customerDocRef, {
        email,
        name,
        pricefile: selectedPriceFile.value,
        salescode: salesCode.value,
      });

      const pricefileAccessRef = doc(db, "pricefileAccess", selectedPriceFile.value);
      const pricefileAccessSnap = await getDoc(pricefileAccessRef);

      if (pricefileAccessSnap.exists()) {
        const existingSalesCodes = pricefileAccessSnap.data().salescodes || [];
        const existingCustomers = pricefileAccessSnap.data().customers || [];

        const salesCodesToUpdate = existingSalesCodes.includes(salesCode.value)
          ? existingSalesCodes
          : [...existingSalesCodes, salesCode.value];

        await updateDoc(pricefileAccessRef, {
          customers: arrayUnion(customerNumber),
          salescodes: salesCodesToUpdate,
        });
      } else {
        await setDoc(pricefileAccessRef, {
          customers: [customerNumber],
          salescodes: [salesCode.value],
        });
      }

      if (currentUser) {
        const creatorDoc = await getDoc(doc(db, "users", currentUser.uid));
        const creatorName = creatorDoc.exists() ? creatorDoc.data().name : "Unknown User";
        await logAction(
          currentUser.uid,
          "Customer Creation",
          `Created customer ${name} (ID: ${customerNumber}) with PriceFile: ${selectedPriceFile.value}, SalesCode: ${salesCode.value}`
        );
      }

      setSuccessMessage("Customer created successfully!");
      setCustomerNumber("");
      setEmail("");
      setName("");
      setSelectedPriceFile(null);
      setSalesCode("");
    } catch (error) {
      setError("An error occurred while creating the customer.");
    }
  };

  return (
    <div className="min-h-screen bg-gray-900 text-white flex items-center justify-center">
      <div className="bg-gray-800 p-8 rounded-lg shadow-lg w-full max-w-lg border border-gray-700">
        <h1 className="text-2xl font-bold text-center text-primary mb-4">Create a New Customer</h1>

        {/* Error Message */}
        {error && (
          <div className="flex items-center bg-red-600 text-white px-4 py-2 rounded-md mb-4 shadow">
            <FiAlertTriangle className="text-xl mr-2" />
            {error}
          </div>
        )}

        {/* Success Message */}
        {successMessage && (
          <div className="flex items-center bg-green-600 text-white px-4 py-2 rounded-md mb-4 shadow">
            <FiCheckCircle className="text-xl mr-2" />
            {successMessage}
          </div>
        )}

        <form onSubmit={handleCreateCustomer} className="space-y-4">
          <input
            type="text"
            placeholder="Customer Account Number"
            value={customerNumber}
            onChange={(e) => setCustomerNumber(e.target.value)}
            className="w-full px-4 py-2 bg-gray-700 border border-gray-600 rounded-md focus:ring focus:ring-primary text-white placeholder-gray-400 shadow-md"
          />

          <input
            type="text"
            placeholder="Customer Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            className="w-full px-4 py-2 bg-gray-700 border border-gray-600 rounded-md focus:ring focus:ring-primary text-white placeholder-gray-400 shadow-md"
          />

          <input
            type="email"
            placeholder="Customer Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="w-full px-4 py-2 bg-gray-700 border border-gray-600 rounded-md focus:ring focus:ring-primary text-white placeholder-gray-400 shadow-md"
          />

          <Select options={priceFiles} value={selectedPriceFile} onChange={setSelectedPriceFile} styles={customStyles} placeholder="Select Price File" />
          <Select options={salesCodeOptions} value={salesCode} onChange={setSalesCode} styles={customStyles} placeholder="Select Sales Code" />

          <button type="submit" className="w-full flex items-center justify-center gap-2 bg-primary hover:bg-opacity-80 text-white py-2 rounded-md transition-all">
            <FiUserPlus className="text-xl" />
            Create Customer
          </button>
        </form>
      </div>
    </div>
  );
};

export default CreateCustomerForm;
