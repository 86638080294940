import React, { useState, useEffect } from "react";
import { db } from "../firebase/firebase-config";
import { collection, getDocs, doc, setDoc } from "firebase/firestore";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import { FiCheckCircle, FiPlusCircle, FiUpload, FiDownload } from "react-icons/fi";

const MissingProductChecker = () => {
  const [missingProducts, setMissingProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [updatedProducts, setUpdatedProducts] = useState({});
  const [newCategory, setNewCategory] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);

      // Get all existing product info documents
      const productInfoSnapshot = await getDocs(collection(db, "productinfo"));
      const existingProductCodes = new Set(productInfoSnapshot.docs.map((doc) => doc.id));

      // Extract existing categories from productinfo
      const extractedCategories = [...new Set(productInfoSnapshot.docs.map(doc => doc.data().category).filter(Boolean))];
      setCategories(extractedCategories.map((cat) => ({ value: cat, label: cat })));

      // Get all pricefiles
      const priceFilesSnapshot = await getDocs(collection(db, "pricefiles"));
      let missingItems = [];

      for (const priceFileDoc of priceFilesSnapshot.docs) {
        const data = priceFileDoc.data();
        if (data.items && Array.isArray(data.items)) {
          for (const item of data.items) {
            if (!existingProductCodes.has(item.itemcode)) {
              missingItems.push({
                itemcode: item.itemcode,
                category: "",
                userFriendlyDesc: item.userFriendlyDesc || "",
              });
            }
          }
        }
      }

      setMissingProducts(missingItems);
      setIsLoading(false);
    };

    fetchData();
  }, []);

  const handleUpdateField = (itemcode, field, value) => {
    setUpdatedProducts((prev) => ({
      ...prev,
      [itemcode]: { ...prev[itemcode], [field]: value },
    }));
  };

  const handleNewCategoryChange = (itemcode, value) => {
    setNewCategory((prev) => ({
      ...prev,
      [itemcode]: value,
    }));
  };

  const handleCreateProduct = async (itemcode) => {
    const updatedItem = updatedProducts[itemcode] || {};
    const selectedCategory = updatedItem.category === "NEW_CATEGORY" ? newCategory[itemcode] : updatedItem.category;

    if (!selectedCategory || !updatedItem.userFriendlyDesc) {
      alert("Please select a category and provide a description.");
      return;
    }

    await setDoc(doc(db, "productinfo", itemcode), {
      category: selectedCategory,
      userFriendlyDesc: updatedItem.userFriendlyDesc,
    });

    setMissingProducts((prev) => prev.filter((p) => p.itemcode !== itemcode));
  };

  return (
    <div className="min-h-screen bg-gray-900 text-white flex flex-col items-center p-8">
      <h2 className="text-2xl font-bold mb-4">Missing Products Checker</h2>

      {/* Navigation Buttons */}
      <div className="flex gap-4 mb-6">
        <button
          onClick={() => navigate("/ExportMissingProducts")}
          className="bg-primary hover:bg-opacity-80 text-white px-4 py-2 rounded-md flex items-center gap-2"
        >
          <FiDownload /> Export to Excel
        </button>
        <button
          onClick={() => navigate("/ImportProductUpdates")}
          className="bg-primary hover:bg-opacity-80 text-white px-4 py-2 rounded-md flex items-center gap-2"
        >
          <FiUpload /> Import from Excel
        </button>
      </div>

      {isLoading ? (
        <p className="text-gray-400">Checking for missing products...</p>
      ) : missingProducts.length > 0 ? (
        <div className="w-full max-w-7xl bg-gray-800 p-6 rounded-lg shadow-lg">
          <h3 className="text-lg font-semibold mb-3">Missing Products</h3>
          <table className="w-full border border-gray-700">
            <thead>
              <tr className="bg-gray-700">
                <th className="p-3">Item Code</th>
                <th className="p-3">Category</th>
                <th className="p-3">Description</th>
                <th className="p-3">Actions</th>
              </tr>
            </thead>
            <tbody>
              {missingProducts.map((item) => (
                <tr key={item.itemcode} className="border-b border-gray-700">
                  <td className="p-3">{item.itemcode}</td>
                  <td className="p-3">
                    <Select
                      options={[...categories, { value: "NEW_CATEGORY", label: "➕ Add New Category" }]}
                      value={categories.find((c) => c.value === (updatedProducts[item.itemcode]?.category || item.category))}
                      onChange={(selected) => handleUpdateField(item.itemcode, "category", selected.value)}
                      isSearchable
                      placeholder="Select Category"
                      className="text-black"
                    />
                    {updatedProducts[item.itemcode]?.category === "NEW_CATEGORY" && (
                      <input
                        type="text"
                        placeholder="Enter new category"
                        value={newCategory[item.itemcode] || ""}
                        onChange={(e) => handleNewCategoryChange(item.itemcode, e.target.value)}
                        className="w-full px-3 py-2 mt-2 bg-gray-800 border border-gray-700 rounded-md text-white"
                      />
                    )}
                  </td>
                  <td className="p-3">
                    <input
                      type="text"
                      placeholder="Enter description"
                      value={updatedProducts[item.itemcode]?.userFriendlyDesc || item.userFriendlyDesc}
                      onChange={(e) => handleUpdateField(item.itemcode, "userFriendlyDesc", e.target.value)}
                      className="w-full px-3 py-2 bg-gray-800 border border-gray-700 rounded-md text-white"
                    />
                  </td>
                  <td className="p-3">
                    <button
                      onClick={() => handleCreateProduct(item.itemcode)}
                      className="bg-primary hover:bg-opacity-80 text-white px-3 py-2 rounded-md flex items-center gap-2"
                    >
                      <FiPlusCircle /> Create
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <p className="text-green-400 flex items-center gap-2">
          <FiCheckCircle /> All item codes exist in productinfo!
        </p>
      )}
    </div>
  );
};

export default MissingProductChecker;
