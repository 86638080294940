import React, { useState } from "react";
import { db } from "../firebase/firebase-config";
import { doc, setDoc } from "firebase/firestore";
import * as XLSX from "xlsx";
import { FiUpload } from "react-icons/fi";

const ImportProductUpdates = () => {
  const [file, setFile] = useState(null);
  const [isUploading, setIsUploading] = useState(false);

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleUpload = async () => {
    if (!file) {
      alert("Please select an Excel file first.");
      return;
    }

    setIsUploading(true);

    const reader = new FileReader();
    reader.onload = async (event) => {
      const data = new Uint8Array(event.target.result);
      const workbook = XLSX.read(data, { type: "array" });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const jsonData = XLSX.utils.sheet_to_json(sheet);

      const batchUpdates = jsonData.map(async (product) => {
        if (product.itemcode && product.category && product.userFriendlyDesc) {
          const productDocRef = doc(db, "productinfo", product.itemcode);
          return setDoc(productDocRef, {
            category: product.category,
            userFriendlyDesc: product.userFriendlyDesc,
          });
        }
      });

      await Promise.all(batchUpdates);
      alert("Products updated successfully!");
      setIsUploading(false);
    };

    reader.readAsArrayBuffer(file);
  };

  return (
    <div className="min-h-screen bg-gray-900 text-white flex flex-col items-center p-8">
      <h2 className="text-2xl font-bold mb-4">Import Updated Products</h2>
      <input
        type="file"
        accept=".xlsx, .xls"
        onChange={handleFileChange}
        className="mb-4 p-2 bg-gray-800 border border-gray-700 rounded-md text-white"
      />
      <button
        onClick={handleUpload}
        disabled={!file || isUploading}
        className="bg-primary hover:bg-opacity-80 text-white px-4 py-2 rounded-md flex items-center gap-2"
      >
        <FiUpload /> {isUploading ? "Uploading..." : "Upload & Update Firestore"}
      </button>
    </div>
  );
};

export default ImportProductUpdates;
