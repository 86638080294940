import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { AuthProvider } from './firebase/AuthContext';
import React from 'react';
import LogIn from './components/LogIn';
import SignUp from './components/SignUp';
import AwaitingApproval from './components/AwaitingApproval';
import SalesPriceFile from './components/SalesPriceFile';
import PriceFileList from './components/PriceFileList';
import UploadCSV from './components/UploadCSV';
import UploadFirestoreCSV from './components/UploadFirestoreCSV';
import ForgotPassword from './components/ForgotPassword';
import Dashboard from './components/Dashboard';
import CreateCustomerForm from './components/CreateCustomerForm';
import UpdateCustomerForm from './components/UpdateCustomerForm';
import UserApprovalDashboard from './components/UserApprovalDashboard';
import UserManagement from './components/UserManagement';
import ActivityLogViewer from './components/ActivityLogViewer';
import UpdateSalesCode from './components/UpdateSalesCode';
import UpdateSalesCodesArray from './components/UpdateSalesCodesArray';
import CustomerExport from './components/CustomerExport';
import ProtectedRoute from './firebase/ProtectedRoute'; 
import UploadCustomerInfo from './components/UploadCustomerInfo';
import UploadCustomerCSV from './components/UploadCustomerCSV';
import ProductInfoDashboard from './components/ProductInfoDashboard';
import ThermalStore from './components/ThermalStore';
import ThermalQuestion from './components/ThermalQuestion';
import CustomerList from './components/CustomerList';
import AccessDenied from './components/AccessDenied';
import BulkUpdateCustomers from './components/BulkUpdateCustomers';
import MissingProductChecker from './components/MissingProductChecker';
import ImportProductUpdates from './components/ImportProductUpdates';
import ExportMissingProducts from './components/ExportMissingProducts';

function App() {
    return (
      <AuthProvider>
        <Router>
          <Routes>
              <Route path="/" element={<LogIn />}/>
              <Route path="/signup" element={<SignUp />}/> 
              <Route path="/AwaitingApproval" element={<AwaitingApproval />}/> 
              <Route path="/ForgotPassword" element={<ForgotPassword />}/>
              <Route path="/Dashboard" element={
              <ProtectedRoute>
                <Dashboard />
              </ProtectedRoute>
              } />
              <Route path="/SalesPriceFile/:fileId" element={
              <ProtectedRoute allowedRoles={['sales', 'admin']}>
                <SalesPriceFile />
              </ProtectedRoute>
              } />
              <Route path="/AccessDenied" element={
              <ProtectedRoute allowedRoles={['sales', 'admin']}>
                <AccessDenied />
              </ProtectedRoute>
              } />
              <Route path="/PriceFileList" element={
              <ProtectedRoute allowedRoles={['sales', 'admin']}>
                <PriceFileList />
              </ProtectedRoute>
              } />
              <Route path="/CustomerList" element={
              <ProtectedRoute allowedRoles={['sales', 'admin']}>
                <CustomerList />
              </ProtectedRoute>
              } />
              <Route path="/ProductInfoDashboard" element={
              <ProtectedRoute allowedRoles={['sales', 'admin']}>
                <ProductInfoDashboard />
              </ProtectedRoute>
              } />
              <Route path="/ThermalStore" element={
              <ProtectedRoute allowedRoles={['sales', 'admin']}>
                <ThermalStore />
              </ProtectedRoute>
              } />
              <Route path="/ThermalQuestion" element={
              <ProtectedRoute allowedRoles={['sales', 'admin']}>
                <ThermalQuestion />
              </ProtectedRoute>
              } />
              <Route path="/UploadCSV" element={
              <ProtectedRoute allowedRoles={['admin']}>
                <UploadCSV />
              </ProtectedRoute>
              } />
              <Route path="/CreateCustomerForm" element={
              <ProtectedRoute allowedRoles={['admin']}>
                <CreateCustomerForm />
              </ProtectedRoute>
              } />
              <Route path="/UserManagement" element={
              <ProtectedRoute allowedRoles={['admin']}>
                <UserManagement />
              </ProtectedRoute>
              } />
              <Route path="/UpdateCustomerForm" element={
              <ProtectedRoute allowedRoles={['admin']}>
                <UpdateCustomerForm />
              </ProtectedRoute>
              } />
              <Route path="/UserApprovalDashboard" element={
              <ProtectedRoute allowedRoles={['admin']}>
                <UserApprovalDashboard />
              </ProtectedRoute>
              } />
              <Route path="/MissingProductChecker" element={
              <ProtectedRoute allowedRoles={['admin']}>
                <MissingProductChecker />
              </ProtectedRoute>
              } />
              <Route path="/ImportProductUpdates" element={
              <ProtectedRoute allowedRoles={['admin']}>
                <ImportProductUpdates />
              </ProtectedRoute>
              } />
              <Route path="/ExportMissingProducts" element={
              <ProtectedRoute allowedRoles={['admin']}>
                <ExportMissingProducts />
              </ProtectedRoute>
              } />
              <Route path="/UploadFirestoreCSV" element={
              <ProtectedRoute allowedRoles={['peter']}>
                <UploadFirestoreCSV />
              </ProtectedRoute>
              } />
              <Route path="/BulkUpdateCustomers" element={
              <ProtectedRoute allowedRoles={['peter']}>
                <BulkUpdateCustomers />
              </ProtectedRoute>
              } />
              <Route path="/UploadCustomerInfo" element={
              <ProtectedRoute allowedRoles={['peter']}>
                <UploadCustomerInfo />
              </ProtectedRoute>
              } />
              <Route path="/CustomerExport" element={
              <ProtectedRoute allowedRoles={['peter']}>
                <CustomerExport />
              </ProtectedRoute>
              } />
              <Route path="/UpdateSalesCodesArray" element={
              <ProtectedRoute allowedRoles={['peter']}>
                <UpdateSalesCodesArray />
              </ProtectedRoute>
              } />
              <Route path="/UpdateSalesCode" element={
              <ProtectedRoute allowedRoles={['peter']}>
                <UpdateSalesCode />
              </ProtectedRoute>
              } />
              <Route path="/UploadCustomerCSV" element={
              <ProtectedRoute allowedRoles={['peter']}>
                <UploadCustomerCSV />
              </ProtectedRoute>
              } />
              <Route path="/ActivityLogViewer" element={
              <ProtectedRoute allowedRoles={['peter']}>
                <ActivityLogViewer />
              </ProtectedRoute>
              } />
          </Routes>
        </Router>
      </AuthProvider>
    );
}

export default App;
