import React, { useState, useEffect } from "react";
import { db } from "../firebase/firebase-config"; // Adjust as needed
import { collection, getDocs, orderBy, query } from "firebase/firestore";
import { FiClock, FiSearch, FiList, FiUser } from "react-icons/fi";

const ActivityLogViewer = () => {
  const [logs, setLogs] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedLog, setSelectedLog] = useState(null);

  useEffect(() => {
    const fetchLogs = async () => {
      const q = query(collection(db, "activityLogs"), orderBy("timestamp", "desc"));
      const snapshot = await getDocs(q);
      const logsData = snapshot.docs.map((doc) => doc.data());
      setLogs(logsData);
    };

    fetchLogs();
  }, []);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value.toLowerCase());
  };

  const filteredLogs = logs.filter(
    (log) =>
      log.userName.toLowerCase().includes(searchQuery) ||
      log.userId.toLowerCase().includes(searchQuery) ||
      log.action.toLowerCase().includes(searchQuery) ||
      log.details.toLowerCase().includes(searchQuery) ||
      new Date(log.timestamp).toLocaleString().toLowerCase().includes(searchQuery)
  );

  return (
    <div className="min-h-screen bg-gray-900 text-white flex">
      {/* Left Panel: Log List (Scrolls Independently) */}
      <div className="w-1/4 bg-gray-800 border-r border-gray-700 p-6 h-screen overflow-y-auto">
        <h2 className="text-xl font-bold mb-4 flex items-center gap-2">
          <FiList className="text-2xl" /> Activity Logs
        </h2>

        {/* Search Bar */}
        <div className="relative mb-4">
          <FiSearch className="absolute left-3 top-3 text-gray-400" />
          <input
            type="text"
            placeholder="Search logs..."
            value={searchQuery}
            onChange={handleSearchChange}
            className="w-full pl-10 pr-3 py-2 bg-gray-700 border border-gray-600 rounded-md text-white focus:ring focus:ring-primary placeholder-gray-400"
          />
        </div>

        {/* Scrollable Log Entries List */}
        <ul className="space-y-2">
          {filteredLogs.length > 0 ? (
            filteredLogs.map((log, index) => (
              <li
                key={index}
                className={`p-2 rounded-md cursor-pointer hover:bg-primary transition-all ${
                  selectedLog === log ? "bg-primary" : "bg-gray-700"
                }`}
                onClick={() => setSelectedLog(log)}
              >
                <span className="block font-semibold">{log.action}</span>
                <span className="text-xs text-gray-400">{new Date(log.timestamp).toLocaleString()}</span>
              </li>
            ))
          ) : (
            <p className="text-gray-400">No logs found.</p>
          )}
        </ul>
      </div>

      {/* Right Panel: Log Details */}
      <div className="flex-1 p-8">
        {selectedLog ? (
          <div className="bg-gray-800 p-6 rounded-lg border border-gray-700">
            <h1 className="text-2xl font-bold flex items-center gap-2">
              <FiUser className="text-3xl" /> {selectedLog.userName}
            </h1>
            <p className="text-gray-400 mb-4">User ID: {selectedLog.userId}</p>

            <div className="space-y-3">
              <p className="text-lg">
                <strong>Action:</strong> {selectedLog.action}
              </p>
              <p className="text-gray-300">
                <strong>Details:</strong> {selectedLog.details}
              </p>
              <p className="text-gray-300 flex items-center gap-2">
                <FiClock /> <strong>Timestamp:</strong> {new Date(selectedLog.timestamp).toLocaleString()}
              </p>
            </div>
          </div>
        ) : (
          <p className="text-gray-400 text-center">Select a log entry to view details.</p>
        )}
      </div>
    </div>
  );
};

export default ActivityLogViewer;
