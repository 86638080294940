import React, { useState, useEffect } from "react";
import Select from "react-select";
import { db } from "../firebase/firebase-config";
import { collection, getDocs, doc, updateDoc, getDoc, setDoc, arrayUnion } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import logAction from "./logger";
import { FiEdit, FiCheckCircle, FiAlertTriangle } from "react-icons/fi";

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    backgroundColor: "#374151",
    borderColor: state.isFocused ? "#b2894d" : "#4b5563",
    color: "white",
    padding: "4px",
    borderRadius: "6px",
    boxShadow: state.isFocused ? "0 0 5px #b2894d" : "none",
    "&:hover": { borderColor: "#b2894d" },
  }),
  menu: (provided) => ({
    ...provided,
    backgroundColor: "#1f2937",
    borderRadius: "6px",
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused ? "#b2894d" : "#1f2937",
    color: state.isFocused ? "black" : "white",
    padding: "10px",
    cursor: "pointer",
  }),
  multiValue: (provided) => ({
    ...provided,
    backgroundColor: "#b2894d",
    color: "black",
  }),
  multiValueLabel: (provided) => ({
    ...provided,
    color: "black",
  }),
  singleValue: (provided) => ({
    ...provided,
    color: "white",
  }),
  input: (provided) => ({
    ...provided,
    color: "white", // Ensures the typed text is white
  }),
  placeholder: (provided) => ({
    ...provided,
    color: "white", // Ensures placeholder text is white
  }),
};

const UpdateCustomerForm = () => {
  const [customers, setCustomers] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [priceFiles, setPriceFiles] = useState([]);
  const [selectedPriceFile, setSelectedPriceFile] = useState(null);
  const [salesCodes, setSalesCodes] = useState([]);
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const auth = getAuth();
  const currentUser = auth.currentUser;

  const salesCodeOptions = [
    { value: "1", label: "1 - Adam Armstrong" },
    { value: "2", label: "2 - Oliver Harrison" },
    { value: "3", label: "3 - Joe Forte" },
    { value: "4", label: "4 - R & D Wightwick" },
    { value: "5", label: "5 - Toni Bedford" },
    { value: "6", label: "6 - Stuart Small" },
    { value: "7", label: "7 - John Dunn" },
    { value: "8", label: "8 - Teresa Richards" },
    { value: "9", label: "9 - John Dunn" },
    { value: "CLOSED", label: "Closed" },
    { value: "TL", label: "TL" },
    { value: "SERVICE", label: "Service" },
  ];

  useEffect(() => {
    const fetchPriceFiles = async () => {
      const snapshot = await getDocs(collection(db, "pricefiles"));
      const priceFilesData = snapshot.docs.map((doc) => ({
        value: doc.id,
        label: doc.id,
      }));
      setPriceFiles(priceFilesData);
    };

    fetchPriceFiles();
  }, []);

  useEffect(() => {
    const fetchCustomers = async () => {
      const snapshot = await getDocs(collection(db, "customerinfo"));
      const customersData = snapshot.docs.map((doc) => ({
        value: doc.id,
        label: `${doc.id} - ${doc.data().name}`,
        data: doc.data(),
      }));
      setCustomers(customersData);
    };

    fetchCustomers();
  }, []);

  const handleCustomerChange = (selectedOption) => {
    setSelectedCustomer(selectedOption);
    setSelectedPriceFile({ value: selectedOption.data.pricefile, label: selectedOption.data.pricefile });
    setSalesCodes((selectedOption.data.salescode || []).map((code) => salesCodeOptions.find((option) => option.value === code)));
    setEmail(selectedOption.data.email);
    setName(selectedOption.data.name);
  };

  const handleUpdateCustomer = async (e) => {
    e.preventDefault();
    setError(null);
    setSuccessMessage(null);

    if (!selectedCustomer || !selectedPriceFile || salesCodes.length === 0 || !email || !name) {
      setError("All fields are required.");
      return;
    }

    try {
      const customerDocRef = doc(db, "customerinfo", selectedCustomer.value);

      await updateDoc(customerDocRef, {
        email,
        name,
        pricefile: selectedPriceFile.value,
        salescode: salesCodes.map((code) => code.value),
      });

      const pricefileAccessRef = doc(db, "pricefileAccess", selectedPriceFile.value);
      const pricefileAccessSnap = await getDoc(pricefileAccessRef);

      if (pricefileAccessSnap.exists()) {
        const existingSalesCodes = pricefileAccessSnap.data().salescodes || [];
        const updatedSalesCodes = [...new Set([...existingSalesCodes, ...salesCodes.map((code) => code.value)])];

        await updateDoc(pricefileAccessRef, {
          customers: arrayUnion(selectedCustomer.value),
          salescodes: updatedSalesCodes,
        });
      } else {
        await setDoc(pricefileAccessRef, {
          customers: [selectedCustomer.value],
          salescodes: salesCodes.map((code) => code.value),
        });
      }

      if (currentUser) {
        const updaterDoc = await getDoc(doc(db, "users", currentUser.uid));
        const updaterName = updaterDoc.exists() ? updaterDoc.data().name : "Unknown User";
        await logAction(
          currentUser.uid,
          "Customer Update",
          `Updated customer ${name} (ID: ${selectedCustomer.value}) with PriceFile: ${selectedPriceFile.value}, SalesCodes: ${salesCodes
            .map((code) => code.value)
            .join(", ")}`
        );
      }

      setSuccessMessage("Customer updated successfully!");
    } catch (error) {
      setError("An error occurred while updating the customer.");
    }
  };

  return (
    <div className="min-h-screen bg-gray-900 text-white flex items-center justify-center">
      <div className="bg-gray-800 p-8 rounded-lg shadow-lg w-full max-w-lg border border-gray-700">
        <h1 className="text-2xl font-bold text-center text-primary mb-4">Update Customer</h1>

        {error && (
          <div className="flex items-center bg-red-600 text-white px-4 py-2 rounded-md mb-4 shadow">
            <FiAlertTriangle className="text-xl mr-2" />
            {error}
          </div>
        )}

        {successMessage && (
          <div className="flex items-center bg-green-600 text-white px-4 py-2 rounded-md mb-4 shadow">
            <FiCheckCircle className="text-xl mr-2" />
            {successMessage}
          </div>
        )}

        <form onSubmit={handleUpdateCustomer} className="space-y-4">
          <Select options={customers} value={selectedCustomer} onChange={handleCustomerChange} styles={customStyles} placeholder="Select Customer" />
          <input type="email" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} className="w-full px-4 py-2 bg-gray-700 border border-gray-600 rounded-md text-white placeholder-gray-400 shadow-md" />
          <input type="text" placeholder="Customer Name" value={name} onChange={(e) => setName(e.target.value)} className="w-full px-4 py-2 bg-gray-700 border border-gray-600 rounded-md text-white placeholder-gray-400 shadow-md" />
          <Select options={priceFiles} value={selectedPriceFile} onChange={setSelectedPriceFile} styles={customStyles} placeholder="Select Price File" />
          <Select options={salesCodeOptions} value={salesCodes} onChange={setSalesCodes} isMulti styles={customStyles} placeholder="Select Sales Codes" />
          <button type="submit" className="w-full flex items-center justify-center gap-2 bg-primary hover:bg-opacity-80 text-white py-2 rounded-md transition-all">
            <FiEdit className="text-xl" />
            Update Customer
          </button>
        </form>
      </div>
    </div>
  );
};

export default UpdateCustomerForm;
