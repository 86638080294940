import React, { useState, useEffect, useCallback } from "react";
import { db } from "../firebase/firebase-config";
import { collection, getDocs } from "firebase/firestore";
import { FiDownload } from "react-icons/fi";
import * as XLSX from "xlsx";

const ExportMissingProducts = () => {
  const [missingProducts, setMissingProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const fetchData = useCallback(async () => {
    try {
      setIsLoading(true);
      
      // Fetch all productinfo documents
      const productInfoSnapshot = await getDocs(collection(db, "productinfo"));
      const existingProductCodes = new Set(productInfoSnapshot.docs.map((doc) => doc.id));

      // Fetch all pricefiles
      const priceFilesSnapshot = await getDocs(collection(db, "pricefiles"));
      let missingItems = [];

      priceFilesSnapshot.docs.forEach((priceFileDoc) => {
        const data = priceFileDoc.data();
        if (data.items && Array.isArray(data.items)) {
          data.items.forEach((item) => {
            if (!existingProductCodes.has(item.itemcode)) {
              missingItems.push({
                itemcode: item.itemcode,
                category: "",
                userFriendlyDesc: item.userFriendlyDesc || "",
              });
            }
          });
        }
      });

      setMissingProducts(missingItems);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching missing products:", error);
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleExportToExcel = () => {
    if (missingProducts.length === 0) {
      alert("No missing products to export.");
      return;
    }

    const worksheet = XLSX.utils.json_to_sheet(missingProducts);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Missing Products");
    XLSX.writeFile(workbook, "Missing_Products.xlsx");
  };

  return (
    <div className="min-h-screen bg-gray-900 text-white flex flex-col items-center p-8">
      <h2 className="text-2xl font-bold mb-4">Export Missing Products</h2>

      {isLoading ? (
        <p className="text-gray-400">Checking for missing products...</p>
      ) : missingProducts.length > 0 ? (
        <button
          onClick={handleExportToExcel}
          className="bg-primary hover:bg-opacity-80 text-white px-4 py-2 rounded-md flex items-center gap-2"
        >
          <FiDownload /> Export to Excel
        </button>
      ) : (
        <p className="text-green-400">All item codes exist in productinfo!</p>
      )}
    </div>
  );
};

export default ExportMissingProducts;
