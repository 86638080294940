import React, { useState, useEffect } from "react";
import { db } from "../firebase/firebase-config";
import { collection, doc, getDoc, getDocs, updateDoc } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import logAction from "./logger";
import { FiUserCheck, FiUsers, FiCheckCircle, FiXCircle } from "react-icons/fi";
import Select from "react-select";

// Custom Styles for Dropdowns
const customStyles = {
  control: (provided, state) => ({
    ...provided,
    backgroundColor: "#374151",
    borderColor: state.isFocused ? "#b2894d" : "#4b5563",
    color: "white",
    padding: "4px",
    borderRadius: "6px",
    boxShadow: state.isFocused ? "0 0 5px #b2894d" : "none",
    "&:hover": { borderColor: "#b2894d" },
  }),
  menu: (provided) => ({
    ...provided,
    backgroundColor: "#1f2937",
    borderRadius: "6px",
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused ? "#b2894d" : "#1f2937",
    color: state.isFocused ? "black" : "white",
    padding: "10px",
    cursor: "pointer",
  }),
  multiValue: (provided) => ({
    ...provided,
    backgroundColor: "#b2894d",
    color: "black",
  }),
  multiValueLabel: (provided) => ({
    ...provided,
    color: "black",
  }),
  singleValue: (provided) => ({
    ...provided,
    color: "white",
  }),
  input: (provided) => ({
    ...provided,
    color: "white", // Ensures the typed text is white
  }),
  placeholder: (provided) => ({
    ...provided,
    color: "white", // Ensures placeholder text is white
  }),
};

const UserApprovalDashboard = () => {
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [priceFiles, setPriceFiles] = useState([]);
  const [selectedPriceFile, setSelectedPriceFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const auth = getAuth();
  const currentUser = auth.currentUser;

  // Fetch Users Pending Approval
  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "users"));
        const usersData = querySnapshot.docs
          .map((doc) => ({ id: doc.id, ...doc.data() }))
          .filter((user) => !user.isApproved && !user.Denied);

        setUsers(usersData);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    fetchUsers();
  }, []);

  // Fetch Price Files
  useEffect(() => {
    const fetchPriceFiles = async () => {
      const snapshot = await getDocs(collection(db, "pricefiles"));
      const priceFilesData = snapshot.docs.map((doc) => ({
        value: doc.id,
        label: doc.id,
      }));
      setPriceFiles(priceFilesData);
    };

    fetchPriceFiles();
  }, []);

  const handleUserSelect = (user) => {
    setSelectedUser(user);
    setSelectedPriceFile(null); // Reset price file selection when switching users
  };

  const handleApproval = async (e) => {
    e.preventDefault();
    if (!selectedUser || !selectedPriceFile) return alert("Please select a price file.");

    setIsLoading(true);
    try {
      const userRef = doc(db, "users", selectedUser.id);
      await updateDoc(userRef, {
        isApproved: true,
        role: ["customer"],
        customer: true,
        sales: false,
        PriceFile: selectedPriceFile.value,
      });

      // Log the approval
      if (currentUser) {
        await logAction(
          currentUser.uid,
          "User Approval",
          `Approved user ${selectedUser.name} (ID: ${selectedUser.id}) with PriceFile: ${selectedPriceFile.value}`
        );
      }

      alert(`User ${selectedUser.name} approved.`);
      setUsers(users.filter((user) => user.id !== selectedUser.id)); // Remove user from list
      setSelectedUser(null);
    } catch (error) {
      console.error("Error approving user:", error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDenial = async () => {
    if (!selectedUser) return;

    setIsLoading(true);
    try {
      const userRef = doc(db, "users", selectedUser.id);
      await updateDoc(userRef, { isApproved: false, Denied: true });

      // Log the denial
      if (currentUser) {
        await logAction(
          currentUser.uid,
          "User Denial",
          `Denied user ${selectedUser.name} (ID: ${selectedUser.id})`
        );
      }

      alert(`User ${selectedUser.name} denied.`);
      setUsers(users.filter((user) => user.id !== selectedUser.id)); // Remove user from list
      setSelectedUser(null);
    } catch (error) {
      console.error("Error denying user:", error.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-gray-900 text-white flex">
      {/* Left Sidebar: List of Users Waiting for Approval */}
      <div className="w-1/4 bg-gray-800 border-r border-gray-700 p-6">
        <h2 className="text-xl font-bold mb-4 flex items-center gap-2">
          <FiUsers className="text-2xl" /> Pending Approvals
        </h2>
        {users.length > 0 ? (
          <ul className="space-y-2">
            {users.map((user) => (
              <li
                key={user.id}
                className={`p-2 rounded-md cursor-pointer hover:bg-primary transition-all ${
                  selectedUser?.id === user.id ? "bg-primary" : "bg-gray-700"
                }`}
                onClick={() => handleUserSelect(user)}
              >
                {user.name}
              </li>
            ))}
          </ul>
        ) : (
          <p className="text-gray-400">No users waiting for approval.</p>
        )}
      </div>

      {/* Right Panel: User Approval Form */}
      <div className="flex-1 p-8">
        {selectedUser ? (
          <form onSubmit={handleApproval} className="grid grid-cols-2 gap-4">
            <h1 className="text-2xl font-bold col-span-2 flex items-center gap-2">
              <FiUserCheck className="text-3xl" /> Approve User
            </h1>

            <div className="col-span-2 bg-gray-800 p-4 rounded-md border border-gray-700">
              <h2 className="text-lg font-semibold">{selectedUser.name}</h2>
              <p className="text-gray-400">{selectedUser.email}</p>
              <p className="text-gray-400">{selectedUser.companyname}</p>
              <p className="text-gray-400">
                {selectedUser.address}, {selectedUser.postcode}
              </p>
            </div>

            <Select
              options={priceFiles}
              value={selectedPriceFile}
              onChange={setSelectedPriceFile}
              isSearchable
              styles={customStyles}
              placeholder="Select a Price File"
              className="col-span-2"
            />

            <button
              type="submit"
              className="col-span-1 bg-green-600 hover:bg-green-700 text-white py-2 rounded-md flex items-center justify-center gap-2"
              disabled={isLoading}
            >
              <FiCheckCircle /> Approve
            </button>

            <button
              type="button"
              className="col-span-1 bg-red-600 hover:bg-red-700 text-white py-2 rounded-md flex items-center justify-center gap-2"
              onClick={handleDenial}
              disabled={isLoading}
            >
              <FiXCircle /> Deny
            </button>
          </form>
        ) : (
          <p className="text-gray-400 text-center">Select a user from the list to review.</p>
        )}
      </div>
    </div>
  );
};

export default UserApprovalDashboard;
