import React from "react";
import { useNavigate } from "react-router-dom";
import { getAuth, signOut } from "firebase/auth";
import { FiBox } from "react-icons/fi"; // Replacing image icons with React icons

const ProductInfoDashboard = () => {
  const auth = getAuth();
  const navigate = useNavigate();

  const handleThermalStoreClick = () => {
    navigate("/ThermalStore");
  };

  return (
    <div className="min-h-screen bg-gray-900 text-white p-8">
      {/* Header */}
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-3xl font-bold text-primary">Product Information</h1>
      </div>

      {/* Widget Grid */}
      <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
        {/* Thermal Store */}
        <div
          onClick={handleThermalStoreClick}
          className="flex flex-col items-center justify-center bg-gray-800 p-6 rounded-lg shadow-lg cursor-pointer hover:bg-gray-700 hover:shadow-xl transition-all"
        >
          <FiBox className="text-primary text-4xl mb-3" />
          <p className="text-lg font-semibold">Thermal Stores</p>
        </div>

        {/* Placeholder for Future Widgets */}
        <div className="flex flex-col items-center justify-center bg-gray-800 p-6 rounded-lg opacity-50">
          <p className="text-gray-500">More categories coming soon...</p>
        </div>
      </div>
    </div>
  );
};

export default ProductInfoDashboard;
