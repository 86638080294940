import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../firebase/firebase-config";
import { FiSearch, FiFolder } from "react-icons/fi";

const SalesPriceFileList = () => {
  const [priceFiles, setPriceFiles] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    const fetchPriceFiles = async () => {
      setIsLoading(true);
      const snapshot = await getDocs(collection(db, "pricefiles"));
      const files = snapshot.docs.map((doc) => doc.id);
      setPriceFiles(files);
      setIsLoading(false);
    };

    fetchPriceFiles();
  }, []);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value.toLowerCase());
  };

  const filteredFiles = priceFiles.filter((file) =>
    file.toLowerCase().includes(searchQuery)
  );

  return (
    <div className="min-h-screen bg-gray-900 text-white p-8">
      {/* Header */}
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-3xl font-bold text-primary">Sales Price Files</h1>
      </div>

      {/* Search Bar */}
      <div className="relative w-full max-w-lg mx-auto mb-6">
        <input
          type="text"
          placeholder="Search Price Files..."
          value={searchQuery}
          onChange={handleSearchChange}
          className="w-full px-4 py-2 bg-gray-800 border border-gray-700 rounded-md focus:ring focus:ring-primary text-white placeholder-gray-400 shadow-md"
        />
        <FiSearch className="absolute right-3 top-3 text-gray-500 text-xl" />
      </div>

      {/* List of Price Files */}
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
        {isLoading ? (
          <p className="text-center text-gray-400">Loading files...</p>
        ) : filteredFiles.length === 0 ? (
          <p className="text-center text-gray-400">No matching files found.</p>
        ) : (
          filteredFiles.map((fileId) => (
            <Link
              key={fileId}
              to={`/SalesPriceFile/${fileId}`}
              className="flex items-center gap-4 bg-gray-800 p-4 rounded-lg shadow-lg cursor-pointer hover:bg-gray-700 hover:shadow-xl transition-all"
            >
              <FiFolder className="text-primary text-3xl" />
              <p className="text-lg font-semibold">{fileId}</p>
            </Link>
          ))
        )}
      </div>
    </div>
  );
};

export default SalesPriceFileList;
