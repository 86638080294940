import React, { useState } from "react";
import Papa from "papaparse";
import { db } from "../firebase/firebase-config";
import { doc, setDoc, getDoc } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import logAction from "./logger";
import { FiUpload, FiCheckCircle, FiAlertTriangle } from "react-icons/fi";

const UploadCSV = () => {
  const [file, setFile] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const auth = getAuth();
  const user = auth.currentUser;

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
    setError(null);
    setSuccessMessage(null);
  };

  const handleUpload = () => {
    if (!file) {
      setError("Please select a CSV file to upload.");
      return;
    }

    setIsUploading(true);
    setError(null);
    setSuccessMessage(null);

    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      complete: async (result) => {
        try {
          const itemsByPriceFile = result.data.reduce((acc, item) => {
            const pricefilecode = item.pricefilecode?.trim() || "";
            if (!pricefilecode) return acc;

            if (!acc[pricefilecode]) {
              acc[pricefilecode] = { items: [], customers: [], salescodes: [] };
            }

            acc[pricefilecode].items.push({
              itemcode: item.itemcode?.trim() || "Unknown",
              desc: item.desc?.trim() || "Unknown",
              unitprice: parseFloat(item.unitprice) || 0,
              startdate: item.startdate?.trim() || "",
              enddate: item.enddate?.trim() || "01/01/2030",
            });

            return acc;
          }, {});

          for (const [pricefilecode, data] of Object.entries(itemsByPriceFile)) {
            const accessDocRef = doc(db, "pricefileAccess", pricefilecode);
            const accessDocSnap = await getDoc(accessDocRef);

            if (!accessDocSnap.exists()) {
              await setDoc(accessDocRef, data);
            }

            const priceFileDocRef = doc(db, "pricefiles", pricefilecode);
            await setDoc(priceFileDocRef, { items: data.items }, { merge: false });
          }

          if (user) {
            await logAction(user.uid, "CSV Upload", `Uploaded ${file.name}`);
          }

          setSuccessMessage("Upload successful!");
        } catch (err) {
          setError("An error occurred while processing the file.");
        } finally {
          setIsUploading(false);
        }
      },
      error: (error) => {
        setError(`Parsing error: ${error.message}`);
        setIsUploading(false);
      },
    });
  };

  return (
    <div className="min-h-screen bg-gray-900 text-white flex items-center justify-center">
      <div className="bg-gray-800 p-8 rounded-lg shadow-lg w-full max-w-md border border-gray-700">
        <h1 className="text-2xl font-bold text-center text-primary mb-4">Upload CSV</h1>

        {error && (
          <div className="flex items-center bg-red-600 text-white px-4 py-2 rounded-md mb-4 shadow">
            <FiAlertTriangle className="text-xl mr-2" />
            {error}
          </div>
        )}

        {successMessage && (
          <div className="flex items-center bg-green-600 text-white px-4 py-2 rounded-md mb-4 shadow">
            <FiCheckCircle className="text-xl mr-2" />
            {successMessage}
          </div>
        )}

        <div className="relative w-full mb-4">
          <input
            type="file"
            accept=".csv"
            onChange={handleFileChange}
            className="hidden"
            id="fileUpload"
          />
          <label
            htmlFor="fileUpload"
            className="cursor-pointer w-full flex items-center justify-center px-4 py-3 bg-gray-700 border border-gray-600 rounded-md text-white text-sm hover:bg-gray-600 transition-all"
          >
            {file ? file.name : "Choose CSV File"}
          </label>
        </div>

        <button
          onClick={handleUpload}
          disabled={isUploading}
          className={`w-full flex items-center justify-center gap-2 bg-primary hover:bg-opacity-80 text-white py-2 rounded-md transition-all ${
            isUploading ? "opacity-50 cursor-not-allowed" : ""
          }`}
        >
          <FiUpload className="text-xl" />
          {isUploading ? "Uploading..." : "Upload CSV"}
        </button>
      </div>
    </div>
  );
};

export default UploadCSV;
