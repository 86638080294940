import React, { useState, useEffect } from "react";
import Select from "react-select";
import { db } from "../firebase/firebase-config";
import { collection, getDocs, doc, updateDoc } from "firebase/firestore";
import { FiSave, FiCheckCircle } from "react-icons/fi";

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    backgroundColor: "#374151",
    borderColor: state.isFocused ? "#b2894d" : "#4b5563",
    color: "white",
    padding: "4px",
    borderRadius: "6px",
    boxShadow: state.isFocused ? "0 0 5px #b2894d" : "none",
    "&:hover": { borderColor: "#b2894d" },
  }),
  menu: (provided) => ({
    ...provided,
    backgroundColor: "#1f2937",
    borderRadius: "6px",
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused ? "#b2894d" : "#1f2937",
    color: state.isFocused ? "black" : "white",
    padding: "10px",
    cursor: "pointer",
  }),
  multiValue: (provided) => ({
    ...provided,
    backgroundColor: "#b2894d",
    color: "black",
  }),
  multiValueLabel: (provided) => ({
    ...provided,
    color: "black",
  }),
  singleValue: (provided) => ({
    ...provided,
    color: "white",
  }),
  input: (provided) => ({
    ...provided,
    color: "white", // Ensures the typed text is white
  }),
  placeholder: (provided) => ({
    ...provided,
    color: "white", // Ensures placeholder text is white
  }),
};

const BulkUpdateCustomers = () => {
  const [customers, setCustomers] = useState([]);
  const [filteredCustomers, setFilteredCustomers] = useState([]);
  const [selectedCustomers, setSelectedCustomers] = useState([]);
  const [priceFiles, setPriceFiles] = useState([]);
  const [selectedPriceFile, setSelectedPriceFile] = useState(null);
  const [selectedSalesCodes, setSelectedSalesCodes] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [filterPriceFile, setFilterPriceFile] = useState(null);
  const [selectAll, setSelectAll] = useState(false);

  const salesCodeOptions = [
    { value: "1", label: "1 - " },
    { value: "2", label: "2 - Oliver Harrison" },
    { value: "3", label: "3 - Joe Forte" },
    { value: "4", label: "4 - R & D Wightwick" },
    { value: "5", label: "5 - Toni Bedford" },
    { value: "6", label: "6 - Stuart Small" },
    { value: "7", label: "7 - John Dunn" },
    { value: "8", label: "8 - Teresa Richards" },
    { value: "9", label: "9 - John Dunn" },
    { value: "CLOSED", label: "Closed" },
    { value: "TL", label: "TL" },
    { value: "SERVICE", label: "Service" },
  ];

  useEffect(() => {
    const fetchPriceFiles = async () => {
      const snapshot = await getDocs(collection(db, "pricefiles"));
      const priceFilesData = snapshot.docs.map((doc) => ({ value: doc.id, label: doc.id }));
      setPriceFiles(priceFilesData);
    };

    const fetchCustomers = async () => {
      const snapshot = await getDocs(collection(db, "customerinfo"));
      const customersData = snapshot.docs.map((doc) => ({
        id: doc.id,
        name: doc.data().name || "",
        email: doc.data().email || "",
        pricefile: doc.data().pricefile || "",
        salescodes: doc.data().salescode || [],
      }));
      setCustomers(customersData);
      setFilteredCustomers(customersData);
    };

    fetchPriceFiles();
    fetchCustomers();
    setIsLoading(false);
  }, []);

  const handleFilterChange = (selected) => {
    setFilterPriceFile(selected);
    if (selected) {
      const filtered = customers.filter((c) => c.pricefile === selected.value);
      setFilteredCustomers(filtered);
    } else {
      setFilteredCustomers(customers);
    }
    setSelectedCustomers([]); // Reset selected when filtering
    setSelectAll(false);
  };

  const handleCustomerSelection = (id) => {
    setSelectedCustomers((prev) =>
      prev.includes(id) ? prev.filter((cid) => cid !== id) : [...prev, id]
    );
  };

  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedCustomers([]);
    } else {
      setSelectedCustomers(filteredCustomers.map((c) => c.id));
    }
    setSelectAll(!selectAll);
  };

  const handleApplyChanges = async () => {
    if (selectedCustomers.length === 0) {
      alert("Please select customers to update.");
      return;
    }

    const updates = selectedCustomers.map(async (customerId) => {
      const customerRef = doc(db, "customerinfo", customerId);
      return updateDoc(customerRef, {
        ...(selectedPriceFile && { pricefile: selectedPriceFile.value }),
        ...(selectedSalesCodes.length > 0 && { salescode: selectedSalesCodes.map((code) => code.value) }),
      });
    });

    await Promise.all(updates);
    alert("Customers updated successfully!");
    window.location.reload();
  };

  return (
    <div className="min-h-screen bg-gray-900 text-white flex">
      {/* Left Panel: Customer List with Filtering */}
      <div className="w-2/3 bg-gray-800 border-r border-gray-700 p-6 overflow-y-auto h-screen">
        <h2 className="text-xl font-bold mb-4">Customer List</h2>

        {/* Price File Filter */}
        <Select
          options={priceFiles}
          value={filterPriceFile}
          onChange={handleFilterChange}
          isClearable
          styles={customStyles}
          placeholder="Filter by Price File"
        />

        {/* Customer Table */}
        <table className="w-full mt-4 border border-gray-700">
          <thead>
            <tr className="bg-gray-700 text-left">
              <th className="p-3">
                <input type="checkbox" checked={selectAll} onChange={handleSelectAll} />
              </th>
              <th className="p-3">Name</th>
              <th className="p-3">Email</th>
              <th className="p-3">Price File</th>
              <th className="p-3">Sales Codes</th>
            </tr>
          </thead>
          <tbody>
            {filteredCustomers.map((customer) => (
              <tr key={customer.id} className="border-b border-gray-700">
                <td className="p-3">
                  <input
                    type="checkbox"
                    checked={selectedCustomers.includes(customer.id)}
                    onChange={() => handleCustomerSelection(customer.id)}
                  />
                </td>
                <td className="p-3">{customer.name}</td>
                <td className="p-3">{customer.email}</td>
                <td className="p-3">{customer.pricefile}</td>
                <td className="p-3">
                  {customer.salescodes.length > 0 ? customer.salescodes.join(", ") : "N/A"}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Right Panel: Bulk Update Form */}
      <div className="flex-1 p-8 overflow-hidden">
        <h2 className="text-2xl font-bold mb-6">Bulk Update Customers</h2>

        <div className="grid grid-cols-2 gap-6">
          {/* Price File Update */}
          <div>
            <h3 className="text-lg mb-2">Change Price File</h3>
            <Select
              options={priceFiles}
              value={selectedPriceFile}
              onChange={setSelectedPriceFile}
              styles={customStyles}
              placeholder="Select new price file"
            />
          </div>

          {/* Sales Codes Update */}
          <div>
            <h3 className="text-lg mb-2">Add Sales Codes</h3>
            <Select
              options={salesCodeOptions}
              value={selectedSalesCodes}
              onChange={setSelectedSalesCodes}
              styles={customStyles}
              isMulti
              placeholder="Select new sales codes"
            />
          </div>
        </div>

        <button
          onClick={handleApplyChanges}
          className="mt-6 bg-primary hover:bg-opacity-80 text-white py-3 px-6 rounded-md flex items-center justify-center gap-2"
        >
          <FiSave /> Apply Changes
        </button>
      </div>
    </div>
  );
};

export default BulkUpdateCustomers;
