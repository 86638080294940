import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { signInWithEmailAndPassword } from "firebase/auth";
import { getFirestore, doc, getDoc } from "firebase/firestore";
import { auth } from "../firebase/firebase-config";
import { motion } from "framer-motion"; // For shake animation
import logo from "./assets/logo.png";

const LoginPage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null); // Track error messages
  const [shake, setShake] = useState(false); // Control shake animation
  const navigate = useNavigate();

  const navigateToSignUp = () => navigate("/SignUp");
  const handleForgotPassword = () => navigate("/ForgotPassword");

  const handleLogin = async () => {
    setError(null); // Reset errors before attempting login
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      if (!user.emailVerified) {
        setError("Please verify your email before logging in.");
        setShake(true);
        return;
      }

      const firestore = getFirestore();
      const userRef = doc(firestore, "users", user.uid);
      const docSnap = await getDoc(userRef);

      if (docSnap.exists() && docSnap.data().isApproved) {
        navigate("/Dashboard");
      } else {
        setError("Your account is pending approval.");
        setShake(true);
      }
    } catch (error) {
      console.error("Error logging in:", error.message);
      setShake(true);

      // Handle different error cases
      switch (error.code) {
        case "auth/invalid-credential":
          setError("Invalid email or password.");
          break;
        case "auth/user-not-found":
          setError("No account found with this email.");
          break;
        case "auth/wrong-password":
          setError("Incorrect password. Try again.");
          break;
        case "auth/too-many-requests":
          setError("Too many failed attempts. Please wait and try again.");
          break;
        default:
          setError("Something went wrong. Please try again.");
      }
    }

    // Reset shake effect after animation completes
    setTimeout(() => setShake(false), 500);
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-background">
      {/* Login Box with Shake Animation on Error */}
      <motion.div
        animate={shake ? { x: [-10, 10, -10, 10, 0] } : {}}
        transition={{ duration: 0.3 }}
        className="bg-gray-900 bg-opacity-95 p-8 rounded-lg shadow-lg w-96 text-white"
      >
        {/* Logo */}
        <div className="flex justify-center mb-6">
          <img src={logo} alt="Company Logo" className="w-24 h-auto" />
        </div>

        <h1 className="text-2xl font-semibold text-center text-primary">Welcome Back</h1>
        <p className="text-gray-400 text-center text-sm">Log in to access the intranet</p>

        <div className="mt-6">
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="w-full px-4 py-2 bg-gray-800 border border-gray-700 rounded-md focus:ring focus:ring-primary text-white placeholder-gray-400"
          />
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="w-full px-4 py-2 mt-4 bg-gray-800 border border-gray-700 rounded-md focus:ring focus:ring-primary text-white placeholder-gray-400"
          />

          {/* Show Error Message */}
          {error && <p className="mt-3 text-sm text-red-400 text-center">{error}</p>}

          <button
            onClick={handleLogin}
            className="w-full mt-4 bg-primary text-white py-2 rounded-md hover:bg-opacity-90 transition-all"
          >
            Log In
          </button>
          <button
            onClick={navigateToSignUp}
            className="w-full mt-2 bg-gray-700 text-white py-2 rounded-md hover:bg-gray-600 transition-all"
          >
            Sign Up
          </button>
          <button
            onClick={handleForgotPassword}
            className="w-full mt-2 text-sm text-primary hover:text-opacity-80 transition-all"
          >
            Forgot Password?
          </button>
        </div>
      </motion.div>
    </div>
  );
};

export default LoginPage;
