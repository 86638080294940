import { FiClock, FiMail, FiPhoneCall } from "react-icons/fi";

const AwaitingApproval = () => {
  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-900 text-white">
      <div className="bg-gray-800 p-10 rounded-lg shadow-lg w-full max-w-lg text-center">
        <h1 className="text-2xl font-semibold mb-4 flex items-center justify-center gap-2">
          <FiClock className="text-xl text-gray-400" /> Account Pending Approval
        </h1>

        <p className="text-gray-300 mb-6">
          Thank you for signing up. Your account is currently under review and will be approved within <b>48 hours</b>.
        </p>

        <div className="bg-gray-700 p-4 rounded-md text-sm text-gray-300">
          <p className="flex items-center gap-2 mb-2">
            <FiMail className="text-gray-400" /> Please ensure your email has been verified.
          </p>
          <p className="flex items-center gap-2">
            <FiPhoneCall className="text-gray-400" /> Need assistance? Contact us:
          </p>
          <p className="mt-2 font-medium text-white">01952 257961</p>
          <p className="font-medium text-white">sales@telford-group.com</p>
        </div>

        {/* Optional: Resend email verification button */}
        <button 
          className="w-full mt-2 bg-primary text-white py-2 rounded-md hover:bg-opacity-80 transition-all">
          Resend Email Verification
        </button>
      </div>
    </div>
  );
};

export default AwaitingApproval;
