import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getAuth, signOut } from "firebase/auth";
import { doc, getDoc, getFirestore } from "firebase/firestore";
import { 
  FiUpload, FiSearch, FiUser, FiFileText, 
  FiDollarSign, FiCheck, FiSettings, FiActivity, FiLogOut 
} from "react-icons/fi";
import { CgAdidas, CgCast } from "react-icons/cg";

const Dashboard = () => {
  const auth = getAuth();
  const navigate = useNavigate();
  const [userData, setUserData] = useState(null);

  const handleNavigation = (path) => {
    if (typeof path === "string") {
      navigate(path);
    } else if (typeof path === "function") {
      path(); // Calls function (for external links)
    }
  };

  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate("/");
    } catch (error) {
      console.error("Logout Failed:", error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const userUid = auth.currentUser?.uid;
      if (userUid) {
        const db = getFirestore();
        const userDocRef = doc(db, "users", userUid);
        const userDoc = await getDoc(userDocRef);

        if (userDoc.exists()) {
          setUserData(userDoc.data());
        } else {
          console.log("No user data found!");
        }
      }
    };

    fetchData();
  }, [auth]);

  const widgets = [
    { role: "sales", path: "/CustomerList", label: "Customers", icon: <FiSearch /> },
    { role: "sales", path: "/ProductInfoDashboard", label: "Products", icon: <FiFileText /> },
    { role: "admin", path: "/PriceFileList", label: "Price Files", icon: <FiDollarSign /> },
    { 
      role: "sales", 
      path: () => window.open("https://www.telford-group.com/downloads/cylinder-data-sheets/", "_blank"),
      label: "Datasheets", 
      icon: <FiFileText /> 
    },
    { role: "admin", path: "/CreateCustomerForm", label: "New Customer", icon: <FiUser /> },
    { role: "admin", path: "/UpdateCustomerForm", label: "Update Customer", icon: <FiSettings /> },
    { role: "admin", path: "/UploadCSV", label: "Upload CSV", icon: <FiUpload /> },
    { role: "admin", path: "/UserManagement", label: "Edit User", icon: <FiUser /> },
    { role: "admin", path: "/UserApprovalDashboard", label: "User Approvals", icon: <FiCheck /> },
    { role: "admin", path: "/MissingProductChecker", label: "Update Missing Products", icon: <CgCast /> },
    { role: "peter", path: "/ActivityLogViewer", label: "Activity Log", icon: <FiActivity /> },
    { role: "peter", path: "/BulkUpdateCustomers", label: "Bulk Update Customers", icon: <CgAdidas /> },
  ];

  return (
    <div className="min-h-screen bg-gray-900 text-white p-6">
      {/* Dashboard Header */}
      <div className="flex justify-between items-center border-b border-gray-700 pb-4">
        <h1 className="text-3xl font-bold">{userData?.name ? `${userData.name}'s Dashboard` : "Dashboard"}</h1>
        <button 
          onClick={handleLogout} 
          className="flex items-center gap-2 bg-red-500 hover:bg-red-600 text-white px-4 py-2 rounded-md transition-all"
        >
          <FiLogOut /> Log Out
        </button>
      </div>

      {/* Grid Layout for Widgets */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6 mt-6">
        {userData &&
          widgets
            .filter((widget) => userData[widget.role])
            .map((widget, index) => (
              <div 
                key={index} 
                className="flex flex-col items-center justify-center p-6 bg-gray-800 rounded-lg shadow-lg hover:shadow-xl transition-all transform hover:-translate-y-1 cursor-pointer"
                onClick={() => handleNavigation(widget.path)}
              >
                <div className="text-4xl text-primary mb-2">{widget.icon}</div>
                <p className="text-lg font-medium">{widget.label}</p>
              </div>
            ))}
      </div>
    </div>
  );
};

export default Dashboard;
