import React, { useState, useEffect } from "react";
import Select from "react-select";
import { db } from "../firebase/firebase-config";
import { collection, doc, getDoc, updateDoc, getDocs } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import logAction from "./logger";
import { FiUserCheck, FiSave, FiUsers } from "react-icons/fi";

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    backgroundColor: "#374151",
    borderColor: state.isFocused ? "#b2894d" : "#4b5563",
    color: "white",
    padding: "4px",
    borderRadius: "6px",
    boxShadow: state.isFocused ? "0 0 5px #b2894d" : "none",
    "&:hover": { borderColor: "#b2894d" },
  }),
  menu: (provided) => ({
    ...provided,
    backgroundColor: "#1f2937",
    borderRadius: "6px",
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused ? "#b2894d" : "#1f2937",
    color: state.isFocused ? "black" : "white",
    padding: "10px",
    cursor: "pointer",
  }),
  multiValue: (provided) => ({
    ...provided,
    backgroundColor: "#b2894d",
    color: "black",
  }),
  multiValueLabel: (provided) => ({
    ...provided,
    color: "black",
  }),
  singleValue: (provided) => ({
    ...provided,
    color: "white",
  }),
  input: (provided) => ({
    ...provided,
    color: "white", // Ensures the typed text is white
  }),
  placeholder: (provided) => ({
    ...provided,
    color: "white", // Ensures placeholder text is white
  }),
};

const salesPersonCodeOptions = [
  { value: "1", label: "1 - Jonathan Marsden" },
  { value: "2", label: "2 - Oliver Harrison" },
  { value: "3", label: "3 - Joe Forte" },
  { value: "4", label: "4 - R & D Wightwick" },
  { value: "5", label: "5 - Robert Soderstrom" },
  { value: "6", label: "6 - Stuart Small" },
  { value: "7", label: "7 - John Dunn" },
  { value: "8", label: "8 - Teresa Richards" },
  { value: "9", label: "9 - John Dunn" },
  { value: "CLOSED", label: "Closed" },
  { value: "TL", label: "TL" },
  { value: "SERVICE", label: "Service" },
];

const UserManagement = () => {
  const [users, setUsers] = useState([]);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [userData, setUserData] = useState({});
  const [priceFiles, setPriceFiles] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const auth = getAuth();
  const currentUser = auth.currentUser;

  // Fetch Users
  useEffect(() => {
    const fetchUsers = async () => {
      setIsLoading(true);
      try {
        const querySnapshot = await getDocs(collection(db, "users"));
        const usersData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          name: doc.data().name || "Unknown User",
        }));
        setUsers(usersData);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
      setIsLoading(false);
    };

    fetchUsers();
  }, []);

  // Fetch Price Files
  useEffect(() => {
    const fetchPriceFiles = async () => {
      const snapshot = await getDocs(collection(db, "pricefiles"));
      const priceFilesData = snapshot.docs.map((doc) => ({
        value: doc.id,
        label: doc.id,
      }));
      setPriceFiles(priceFilesData);
    };

    fetchPriceFiles();
  }, []);

  // Load Selected User's Data
  useEffect(() => {
    const fetchUserData = async () => {
      if (!selectedUserId) return;
      setIsLoading(true);
      const userDocRef = doc(db, "users", selectedUserId);
      const userDoc = await getDoc(userDocRef);

      if (userDoc.exists()) {
        setUserData(userDoc.data());
      }
      setIsLoading(false);
    };

    fetchUserData();
  }, [selectedUserId]);

  const handleUserUpdate = async (e) => {
    e.preventDefault();

    if (userData.admin) {
      alert("Admin accounts cannot be edited.");
      return;
    }

    if (!userData.name || !userData.email || !userData.companyname || !userData.address || !userData.postcode || !userData.PriceFile) {
      alert("All fields are required.");
      return;
    }

    if (userData.sales && userData.salesPersonCodes.length === 0) {
      alert("Please select at least one Sales Person Code if the user is marked as a salesperson.");
      return;
    }

    const userRef = doc(db, "users", selectedUserId);
    await updateDoc(userRef, userData);

    if (currentUser) {
      await logAction(
        currentUser.uid,
        "User Update",
        `Updated user ${userData.name} (ID: ${selectedUserId}) with PriceFile: ${userData.PriceFile}, SalesPersonCodes: ${userData.salesPersonCodes?.join(", ")}, Sales: ${userData.sales}`
      );
    }

    alert("User updated successfully!");
  };

  return (
    <div className="min-h-screen bg-gray-900 text-white flex">
      {/* Left Panel: Scrollable User List */}
      <div className="w-1/4 bg-gray-800 border-r border-gray-700 p-6 overflow-y-auto h-screen">
        <h2 className="text-xl font-bold mb-4 flex items-center gap-2">
          <FiUsers className="text-2xl" /> Users
        </h2>
        {isLoading ? (
          <p className="text-gray-400">Loading users...</p>
        ) : (
          <ul className="space-y-2">
            {users.map((user) => (
              <li
                key={user.id}
                className={`p-2 rounded-md cursor-pointer hover:bg-primary transition-all ${
                  selectedUserId === user.id ? "bg-primary" : "bg-gray-700"
                }`}
                onClick={() => setSelectedUserId(user.id)}
              >
                {user.name}
              </li>
            ))}
          </ul>
        )}
      </div>

      {/* Right Panel: User Edit Form */}
      <div className="flex-1 p-8">
        {selectedUserId ? (
          <form onSubmit={handleUserUpdate} className="grid grid-cols-2 gap-4">
            <h1 className="text-2xl font-bold col-span-2 flex items-center gap-2">
              <FiUserCheck className="text-3xl" /> Edit User
            </h1>

            <input
              type="text"
              placeholder="Name"
              value={userData.name || ""}
              onChange={(e) => setUserData({ ...userData, name: e.target.value })}
              className="col-span-1 px-4 py-2 bg-gray-800 border border-gray-700 rounded-md text-white"
            />
            <input
              type="email"
              placeholder="Email"
              value={userData.email || ""}
              onChange={(e) => setUserData({ ...userData, email: e.target.value })}
              className="col-span-1 px-4 py-2 bg-gray-800 border border-gray-700 rounded-md text-white"
            />
            <input
              type="text"
              placeholder="Company"
              value={userData.companyname || ""}
              onChange={(e) => setUserData({ ...userData, companyname: e.target.value })}
              className="col-span-1 px-4 py-2 bg-gray-800 border border-gray-700 rounded-md text-white"
            />
            <input
              type="text"
              placeholder="Postcode"
              value={userData.postcode || ""}
              onChange={(e) => setUserData({ ...userData, postcode: e.target.value })}
              className="col-span-1 px-4 py-2 bg-gray-800 border border-gray-700 rounded-md text-white"
            />

            {/* Price File Dropdown */}
            <Select
              options={priceFiles}
              value={priceFiles.find((p) => p.value === userData.PriceFile) || null}
              onChange={(selected) => setUserData({ ...userData, PriceFile: selected.value })}
              isSearchable
              styles={customStyles}
              placeholder="Price File"
              className="col-span-2"
            />

            {/* Sales Toggle */}
            <label className="col-span-2 flex items-center gap-2 cursor-pointer">
              <input
                type="checkbox"
                checked={userData.sales || false}
                onChange={(e) => setUserData({ ...userData, sales: e.target.checked })}
                className="accent-primary"
              />
              Sales
            </label>

            {/* Sales Person Codes */}
            {userData.sales && (
              <Select
                options={salesPersonCodeOptions}
                value={salesPersonCodeOptions.filter((option) => userData.salesPersonCodes?.includes(option.value))}
                onChange={(selected) => setUserData({ ...userData, salesPersonCodes: selected.map((s) => s.value) })}
                isMulti
                styles={customStyles}
                placeholder="Sales Person Codes"
                className="col-span-2"
              />
            )}

            <button type="submit" className="col-span-2 bg-primary hover:bg-opacity-80 text-white py-2 rounded-md flex items-center justify-center gap-2">
              <FiSave /> Update User
            </button>
          </form>
        ) : (
          <p className="text-gray-400 text-center">Select a user from the list to edit.</p>
        )}
      </div>
    </div>
  );
};

export default UserManagement;