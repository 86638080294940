import React, { useState } from 'react';
import { resetPassword } from '../firebase/AuthService'; 
import { FiMail, FiSend } from "react-icons/fi";

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage('');
    setError('');

    try {
      await resetPassword(email);
      setMessage('Please check your email to reset your password.');
    } catch (error) {
      setError('Failed to send password reset email.');
      console.error('Error sending reset password email:', error.message);
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-900 text-white">
      <div className="bg-gray-800 p-8 rounded-lg shadow-lg w-full max-w-md">
        <h2 className="text-2xl font-semibold text-center mb-6 flex items-center justify-center gap-2">
          <FiMail className="text-xl text-gray-400" /> Reset Password
        </h2>

        {error && <p className="bg-red-600 text-white p-3 rounded-md mb-4">{error}</p>}
        {message && <p className="bg-green-600 text-white p-3 rounded-md mb-4">{message}</p>}

        <form onSubmit={handleSubmit} className="space-y-4">
          <input
            type="email"
            id="email"
            name="email"
            value={email}
            placeholder="Enter your email"
            onChange={(e) => setEmail(e.target.value)}
            required
            className="w-full px-4 py-2 bg-gray-700 border border-gray-600 rounded-md text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-primary"
          />

          <button 
            type="submit" 
            className="w-full bg-primary text-white py-3 rounded-md font-bold flex items-center justify-center gap-2 hover:bg-opacity-80 transition"
          >
            <FiSend /> Send Reset Email
          </button>
        </form>
      </div>
    </div>
  );
};

export default ForgotPassword;
