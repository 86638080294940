import React, { useState, useEffect } from "react";
import { thermalQuizData } from "./thermalQuizData";
import TristorPDF from "./assets/Tristor.pdf";
import { FiCheckCircle, FiArrowRight, FiRefreshCcw } from "react-icons/fi";

// Image Assets
import TSV1 from "./assets/TSV1.png";
import TSV2 from "./assets/TSV2.png";
import TSV3 from "./assets/TSV3.png";
import TSV4 from "./assets/TSV4.png";
import TSV5 from "./assets/TSV5.png";
import TSC1 from "./assets/TSC1.png";
import TSC2 from "./assets/TSC2.png";
import TSC3 from "./assets/TSC3.png";
import TSC4 from "./assets/TSC4.png";
import TSC5 from "./assets/TSC5.png";

const datasheetMapping = {
  "TSV/1XXX": TSV1,
  "TSV/2XXX": TSV2,
  "TSV/3XXX": TSV3,
  "TSV/4XXX": TSV4,
  "TSV/5XXX": TSV5,
  "TSC/1XXX": TSC1,
  "TSC/2XXX": TSC2,
  "TSC/3XXX": TSC3,
  "TSC/4XXX": TSC4,
  "TSC/5XXX": TSC5,
};

const getSizeOptionsFromSources = (sources) => {
  const hasBoiler = sources.includes("boiler");
  const hasSolar = sources.includes("solar");
  const hasStove = sources.includes("stove");
  const hasElectric = sources.includes("electric");

  if (hasBoiler && hasSolar) return [200, 250, 300];
  if (hasSolar && !hasBoiler) return [144, 160, 200, 250, 300];
  if (hasBoiler && !hasSolar) return [144, 160, 200, 250, 300];
  if (hasStove && !hasBoiler && !hasSolar) return [115, 135, 144, 160, 200, 250, 300];
  if (hasElectric && !hasStove && !hasBoiler && !hasSolar) return [115, 135, 144, 160, 200, 250, 300];

  return [115, 135, 144, 160, 200, 250, 300];
};

const determineVariant = (sources) => {
  if (sources.includes("solar") && sources.includes("boiler")) return "3XXX";
  if (sources.includes("solar") && !sources.includes("boiler")) return "2XXX";
  if (sources.includes("boiler") && !sources.includes("solar")) return "4XXX";
  if (sources.includes("stove") && !sources.includes("solar") && !sources.includes("boiler")) return "1XXX";
  if (sources.includes("electric") && !sources.includes("stove") && !sources.includes("solar") && !sources.includes("boiler")) return "5XXX";

  return "5XXX";
};

const generateSizeAnswers = (sizeArray) => {
  return sizeArray.map((size) => ({
    label: `${size}L`,
    value: size,
    next: 6,
  }));
};

const ThermalQuestion = () => {
  const [currentQuestionId, setCurrentQuestionId] = useState(1);
  const [userAnswers, setUserAnswers] = useState({});
  const [tempSelections, setTempSelections] = useState([]);
  const [quizState, setQuizState] = useState([...thermalQuizData]);

  const currentQuestion = quizState.find((q) => q.id === currentQuestionId);

  useEffect(() => {
    if (currentQuestionId === 4) {
      const sources = userAnswers[3] || [];
      const sizes = getSizeOptionsFromSources(sources);
      setQuizState((prevState) =>
        prevState.map((q) => (q.id === 4 ? { ...q, answers: generateSizeAnswers(sizes) } : q))
      );
    }
  }, [currentQuestionId, userAnswers]);

  const handleMultipleChoiceToggle = (value) => {
    setTempSelections((prev) =>
      prev.includes(value) ? prev.filter((v) => v !== value) : [...prev, value]
    );
  };

  const handleMultipleChoiceNext = () => {
    setUserAnswers((prev) => ({ ...prev, [currentQuestionId]: tempSelections }));
    setCurrentQuestionId(currentQuestion.next);
    setTempSelections([]);
  };

  const handleSingleChoiceSelect = (answerValue, nextId) => {
    setUserAnswers((prev) => ({ ...prev, [currentQuestionId]: [answerValue] }));
    setCurrentQuestionId(nextId);
  };

  const restartQuiz = () => {
    setCurrentQuestionId(1);
    setUserAnswers({});
    setTempSelections([]);
    setQuizState([...thermalQuizData]);
  };

  if (!currentQuestion) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-900 text-white p-6">
        <div className="bg-gray-800 p-8 rounded-lg shadow-lg text-center max-w-xl w-full">
          <h1 className="text-3xl font-bold mb-4">Your Recommended Product</h1>
          <p className="text-primary font-semibold text-xl">{userAnswers[4] ? userAnswers[4][0] : "No selection"}</p>
          <button
            className="w-full mt-6 bg-primary text-white py-3 rounded-md font-bold flex items-center justify-center gap-2 hover:bg-opacity-80 transition"
            onClick={restartQuiz}
          >
            <FiRefreshCcw /> Start Over
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-900 text-white p-6">
      <div className="bg-gray-800 p-8 rounded-lg shadow-lg text-center max-w-xl w-full">
        <h1 className="text-3xl font-bold mb-6">Thermal Store Selector</h1>
        <p className="text-gray-300">{currentQuestion.question}</p>

        {currentQuestion.type === "multiple-choice" && (
          <div className="mt-6">
            {currentQuestion.answers.map((answer, idx) => (
              <label key={idx} className="block bg-gray-700 text-white py-2 px-4 rounded-md mb-2 cursor-pointer hover:bg-primary transition">
                <input
                  type="checkbox"
                  checked={tempSelections.includes(answer.value)}
                  onChange={() => handleMultipleChoiceToggle(answer.value)}
                  className="mr-2"
                />
                {answer.label}
              </label>
            ))}
            <button
              className="w-full mt-4 bg-primary text-white py-3 rounded-md font-bold flex items-center justify-center gap-2 hover:bg-opacity-80 transition"
              onClick={handleMultipleChoiceNext}
            >
              <FiArrowRight /> Next
            </button>
          </div>
        )}

        {currentQuestion.type === "single-choice" && (
          <div className="mt-6">
            {currentQuestion.answers.map((answer, idx) => (
              <button
                key={idx}
                className="w-full bg-gray-700 text-white py-2 px-4 rounded-md mb-2 cursor-pointer hover:bg-primary transition"
                onClick={() => handleSingleChoiceSelect(answer.value, answer.next)}
              >
                {answer.label}
              </button>
            ))}
          </div>
        )}

        <button
          className="w-full mt-6 bg-red-600 text-white py-3 rounded-md font-bold flex items-center justify-center gap-2 hover:bg-red-500 transition"
          onClick={restartQuiz}
        >
          <FiRefreshCcw /> Start Over
        </button>
      </div>
    </div>
  );
};

export default ThermalQuestion;
