import React from "react";
import { useNavigate } from "react-router-dom";
import { FiArrowRight } from "react-icons/fi";
import thermalStorePic from "./assets/thermalstore.png";
import thermalStoreExplainPic from "./assets/ThermalExplain.png";

const ThermalStore = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/ThermalQuestion");
  };

  return (
    <div className="min-h-screen bg-gray-900 text-white py-12 px-6 flex flex-col items-center">
      <div className="max-w-4xl w-full bg-gray-800 p-8 rounded-lg shadow-lg">
        <h1 className="text-3xl font-bold text-center mb-6">
          Understanding Thermal Store Hot Water Cylinders
        </h1>

        <p className="text-gray-300 leading-relaxed">
          A Thermal Store is essentially a heat battery for hot water and heating and works in
          reverse to a typical unvented or vented cylinder. Rather than relying on instantaneous hot
          water, a thermal store keeps a large volume of water at a high temperature, ready to be
          used for both hot water and heating.
        </p>

        <div className="flex justify-center my-6">
          <img
            src={thermalStorePic}
            alt="Thermal Store Diagram"
            className="rounded-lg shadow-md w-full md:w-3/4"
          />
        </div>
        <p className="text-gray-400 text-sm text-center italic">
          AI generated image of a Thermal Store
        </p>

        <h2 className="text-2xl font-semibold mt-8">What is a Thermal Store?</h2>
        <p className="text-gray-300 leading-relaxed">
          Unlike a standard hot water cylinder that simply stores domestic hot water (DHW) until
          it's needed, a thermal store is designed to store energy. This energy can come from a
          variety of sources—gas or oil boilers, solid-fuel stoves, solar thermal panels, or even
          heat pumps. Thermal stores tend to be open-vented, meaning they operate at atmospheric
          pressure, unlike our Unvented range, which operates under pressure.
        </p>

        <h2 className="text-2xl font-semibold mt-8">How Does a Thermal Store Work?</h2>
        <ul className="list-decimal list-inside space-y-4 text-gray-300">
          <li>
            <strong>Heat Input:</strong> The thermal store is connected to one or more heat sources.
            These sources heat the water stored in the thermal store.
          </li>
          <li>
            <strong>Heat Storage:</strong> The large volume of water acts as a thermal reservoir,
            meaning the heat is “banked” for efficient use.
          </li>
          <li>
            <strong>On-Demand Hot Water:</strong> When you open a hot tap, cold mains water passes
            through the coil inside the cylinder, heating up instantly.
          </li>
          <li>
            <strong>Space Heating Support:</strong> Some thermal stores can also provide central
            heating buffer storage.
          </li>
        </ul>

        <div className="flex justify-center my-6">
          <img
            src={thermalStoreExplainPic}
            alt="Thermal Store Diagram"
            className="rounded-lg shadow-md w-full md:w-3/4"
          />
        </div>
        <p className="text-gray-400 text-sm text-center italic">
          Basic explanation of a thermal store
        </p>

        <h2 className="text-2xl font-semibold mt-8">Key Benefits</h2>
        <ul className="list-disc list-inside space-y-4 text-gray-300">
          <li>
            <strong>Versatility:</strong> A thermal store can be integrated with multiple heat
            sources, including renewables.
          </li>
          <li>
            <strong>High Pressure at the Tap:</strong> Hot water is heated via a mains-fed coil,
            providing powerful water pressure.
          </li>
          <li>
            <strong>Improved Efficiency:</strong> By storing heat, you can optimize energy usage and
            reduce costs.
          </li>
          <li>
            <strong>Reduced Complexity:</strong> Unlike unvented systems, no additional safety
            expansion vessels are needed.
          </li>
        </ul>

        <h2 className="text-2xl font-semibold mt-8">Typical Applications & Limitations</h2>
        <p className="text-gray-300 leading-relaxed">
          Thermal store cylinders are ideal for homes that have multiple heat sources or those that
          incorporate renewable technologies. However, one drawback is that they require higher
          internal temperatures (typically 70°C). This makes them incompatible with low-temperature
          heat pump systems unless supplemented with an additional heat source.
        </p>

        <h2 className="text-2xl font-semibold mt-8">Maintenance & Considerations</h2>
        <p className="text-gray-300 leading-relaxed">
          While thermal stores are robust and reliable, they require proper installation and
          occasional check-ups. Ensure the system’s controls and thermostats are functioning
          correctly. Always consult with a qualified heating engineer or plumber for advice on
          sizing and installation.
        </p>

        <h2 className="text-2xl font-semibold mt-8">Tristar vs Tristor</h2>
        <p className="text-gray-300 leading-relaxed">
          In the Telford range, we offer two types of thermal stores: the Tristar and the Tristor
          Manual Fill. The Tristor Manual Fill is designed for self-containment and eliminates the
          need for an overflow. Equipped with a 2-port valve and float switch, it cuts off its own
          supply when the water level is too high.
        </p>
      </div>
    </div>
  );
};

export default ThermalStore;
