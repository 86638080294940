import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { signUp } from '../firebase/AuthService';
import { doc, setDoc, getFirestore } from 'firebase/firestore'; 
import { FiUserPlus } from "react-icons/fi";

const SignUpComponent = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [name, setName] = useState('');
  const [companyname, setCompanyName] = useState('');
  const [address, setAddress] = useState('');
  const [postcode, setPostcode] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleSignUp = async (e) => {
    e.preventDefault();
    setError('');

    const passwordRegex = /^(?=.*[A-Z])(?=.*[!@#$&*])(?=.*[0-9])(?=.*[a-z]).{8,}$/;
    if (!passwordRegex.test(password)) {
      setError('Password must be at least 8 characters, with one uppercase, one number, and one symbol.');
      return;
    }

    try {
      const userCredential = await signUp(email, password);
      const user = userCredential.user;

      const firestore = getFirestore();
      await setDoc(doc(firestore, "users", user.uid), {
        name,
        email,
        isApproved: false,
        emailVerified: false,
        companyname,
        address,
        postcode,
        role: []
      });

      navigate('/AwaitingApproval');
    } catch (error) {
      if (error.code === 'auth/email-already-in-use') {
        setError('This email is already registered.');
      } else {
        setError('Failed to sign up. Please try again.');
      }
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-900 text-white">
      <div className="bg-gray-800 p-8 rounded-lg shadow-lg w-full max-w-lg">
        <h2 className="text-2xl font-bold text-center mb-6 flex items-center justify-center gap-2">
          <FiUserPlus className="text-3xl" /> Sign Up
        </h2>

        {error && <p className="bg-red-600 text-white p-3 rounded-md mb-4">{error}</p>}

        <form onSubmit={handleSignUp} className="space-y-6">
          {/* Personal Details */}
          <div className="space-y-4">
            <p className="font-semibold text-gray-400">Your Details</p>
            <input 
              type="text" 
              value={name} 
              onChange={(e) => setName(e.target.value)} 
              placeholder="Full Name" 
              required 
              className="w-full px-4 py-3 bg-gray-700 border border-gray-600 rounded-md text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-primary" 
            />
            <input 
              type="email" 
              value={email} 
              onChange={(e) => setEmail(e.target.value)} 
              placeholder="Email Address" 
              required 
              className="w-full px-4 py-3 bg-gray-700 border border-gray-600 rounded-md text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-primary" 
            />
            <input 
              type="password" 
              value={password} 
              onChange={(e) => setPassword(e.target.value)} 
              placeholder="Password" 
              required 
              className="w-full px-4 py-3 bg-gray-700 border border-gray-600 rounded-md text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-primary" 
            />
          </div>

          {/* Company Details */}
          <div className="space-y-4">
            <p className="font-semibold text-gray-400">Company Details</p>
            <input 
              type="text" 
              value={companyname} 
              onChange={(e) => setCompanyName(e.target.value)} 
              placeholder="Business Name" 
              required 
              className="w-full px-4 py-3 bg-gray-700 border border-gray-600 rounded-md text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-primary" 
            />
            <input 
              type="text" 
              value={address} 
              onChange={(e) => setAddress(e.target.value)} 
              placeholder="Business Address" 
              required 
              className="w-full px-4 py-3 bg-gray-700 border border-gray-600 rounded-md text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-primary" 
            />
            <input 
              type="text" 
              value={postcode} 
              onChange={(e) => setPostcode(e.target.value)} 
              placeholder="Postcode" 
              required 
              className="w-full px-4 py-3 bg-gray-700 border border-gray-600 rounded-md text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-primary" 
            />
          </div>

          <button 
            type="submit" 
            className="w-full bg-primary text-white p-3 rounded-md font-bold transition hover:bg-opacity-80">
            Sign Up
          </button>
        </form>
      </div>
    </div>
  );
};

export default SignUpComponent;
